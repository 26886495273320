import React from 'react'
import './style.css'
const ImageBanner = () => {
  return (
    <div class="image-banner image-banner-1 full-width-in-main-body" style={{backgroundImage: 'url(/img/nasa-hat.jpg)'}}>
      <div class="banner-text">It's not about the code, it's about the connections.</div>
    </div>
  )
}

export default ImageBanner
